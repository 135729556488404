import React, {useState, useEffect} from "react";
import {Link, Trans, useTranslation, I18nextContext, useI18next} from 'gatsby-plugin-react-i18next';
import Layout from "../components/layout";
import Seo from "../components/seo";
import {graphql} from 'gatsby';
import ReactVideo from "../components/video-player/ReactVideo";
import ReactVideoHomepage from "../components/video-player/ReactVideoHomepage";
import SlickSlider from '../components/carousel/SlickSlider';
import {StaticImage} from "gatsby-plugin-image";
import posterHero from '../images/support/posters/poster-homepage-hero.jpeg';
import posterDigilink from '../images/support/posters/poster-homepage-digilink.jpeg';
const IndexPage = () => {
  const [showModal, setShowModal] = useState(false);
  const {changeLanguage} = useI18next();
  const context = React.useContext(I18nextContext);
  const {t} = useTranslation();

  function handleShowModal() {
    setShowModal(prevShowModal => !prevShowModal);
    document.body.classList.toggle('stop-body-scroll');
  }

  useEffect(() => {
    // if (localStorage.getItem('PIVOT_MODAL') === null) {
    //   handleShowModal();
    //   localStorage.setItem('PIVOT_MODAL', 'seen');
    // }
  }, []);

  return (
      <Layout>
        <Seo
            title={t('home')}
            description={t('des_home')}
        />
        <div className="home">
          <section className="hero">
            <div className="fw-video-holder">
              <div className="video-overlay container" dangerouslySetInnerHTML={{__html: t('hero_video_caption')}}/>
              <ReactVideoHomepage
                  vURL={'https://cdn.mediavalet.com/usva/digilock/U5rcilX-jU-TgI9EYJswcA/gOZElUyLo0m7ZFHhx8Yqqw/Original/video-homepage-hero.mp4'}
                  poster={posterHero}
                  label={t('hero_video_caption')}
              />
            </div>
          </section>
          <section className={'lock-solutions'}>
            <div className="container">
              <h1>
                <Trans>section_one_headline</Trans>
              </h1>
              <div className={'grid'}>
                <div className="col copy-container right">
                  <h2>
                    <Trans>section_one_headline_2</Trans>
                  </h2>
                  <p>
                    <Trans>section_one_headline_2_copy</Trans>
                  </p>
                </div>
                <div className="delimeter"/>
                <div className="col copy-container left">
                  <h2>
                    <Trans>section_one_headline_3</Trans>
                  </h2>
                  <p>
                    <Trans>section_one_headline_3_copy</Trans>
                  </p>
                </div>
              </div>
            </div>
          </section>
          <div className="section-delimeter container"/>

          <section className="clients">
            <div className="container">
              <h2>
                <Trans>known_trusted</Trans>
              </h2>
              <div>
                <SlickSlider/>
              </div>
            </div>
          </section>

          <div className="section-delimeter bump-up container"/>
          <section className="industries">
            <div className="container">
              <h1>
                <Trans>solutions_industry</Trans>
              </h1>

              <div className="industries-grid">
                <Link to={'/solutions/workspace/'}>
                  <div className="col btn-industry workspace">
                    <StaticImage
                        src="../images/industries/hero-workspace.png"
                        quality={100}
                        width={320}
                        height={176}
                        formats={["auto", "webp", "avif"]}
                        loading={'lazy'}
                        alt="workspace lock"
                    />
                    <span><Trans>workspace</Trans></span>
                  </div>
                </Link>
                <Link to={'/solutions/education/'}>
                  <div className="col btn-industry education">
                    <StaticImage
                        src="../images/industries/hero-education.png"
                        quality={100}
                        width={320}
                        height={176}
                        formats={["auto", "webp", "avif"]}
                        loading={'lazy'}
                        alt="education lock"
                    />
                    <span><Trans>education</Trans></span>
                  </div>
                </Link>
                <Link to={'/solutions/digital-locker-locks-gyms/'}>
                  <div className="col btn-industry health-fitness">
                    <StaticImage
                        src="../images/industries/hero-health-fitness.png"
                        quality={100}
                        width={320}
                        height={176}
                        formats={["auto", "webp", "avif"]}
                        loading={'lazy'}
                        alt="health and fitness lock"
                    />
                    <span><Trans>health_fitness</Trans></span>
                  </div>
                </Link>
                <Link to={'/solutions/healthcare/'}>
                  <div className="col btn-industry healthcare">
                    <StaticImage
                        src="../images/industries/hero-healthcare.jpg"
                        quality={100}
                        width={320}
                        height={176}
                        formats={["auto", "webp", "avif"]}
                        loading={'lazy'}
                        alt="healthcare lock"
                    />
                    <span><Trans>healthcare</Trans></span>
                  </div>
                </Link>
                <Link to={'/solutions/retail/'}>
                  <div className="col btn-industry retail">
                    <StaticImage
                        src="../images/industries/hero-retail.png"
                        quality={100}
                        width={320}
                        height={176}
                        formats={["auto", "webp", "avif"]}
                        loading={'lazy'}
                        alt="retail lock"
                    />
                    <span><Trans>retail</Trans></span>
                  </div>
                </Link>
                <Link to={'/solutions/hospitality/'}>
                  <div className="col btn-industry hospitality">
                    <StaticImage
                        src="../images/industries/hero-hospitality.png"
                        quality={100}
                        width={320}
                        height={176}
                        formats={["auto", "webp", "avif"]}
                        loading={'lazy'}
                        alt="hospitality lock"
                    />
                    <span><Trans>hospitality</Trans></span>
                  </div>
                </Link>
                <Link to={'/solutions/pro-sports/'}>
                  <div className={"col btn-industry pro-sports " + context.language}>
                    <StaticImage
                        src="../images/industries/hero-pro-sports.png"
                        quality={100}
                        width={320}
                        height={176}
                        formats={["auto", "webp", "avif"]}
                        loading={'lazy'}
                        alt="pro sports lock"
                    />
                    <span><Trans>pro_sports</Trans></span>
                  </div>
                </Link>
                <Link to={'/solutions/manufacturing/'}>
                  <div className="col btn-industry manufacturing">
                    <StaticImage
                        src="../images/industries/hero-manufacturing.png"
                        quality={100}
                        width={320}
                        height={176}
                        formats={["auto", "webp", "avif"]}
                        loading={'lazy'}
                        alt="manufacturing lock"
                    />
                    <span><Trans>manufacturing</Trans></span>
                  </div>
                </Link>
                <Link to={'/solutions/government/'}>
                  <div className="col btn-industry government">
                    <StaticImage
                        src="../images/industries/hero-government.png"
                        quality={100}
                        width={320}
                        height={176}
                        formats={["auto", "webp", "avif"]}
                        loading={'lazy'}
                        alt="government lock"
                    />
                    <span><Trans>government</Trans></span>
                  </div>
                </Link>

              </div>
            </div>
          </section>
          <div className="section-delimeter container"/>
          <section className="smart">
            <div className="container">
              <h1><Trans>generation_smart</Trans></h1>
              <p><Trans>
                generation_smart_copy
              </Trans></p>
              <ReactVideo
                  vURL={'https://cdn.mediavalet.com/usva/digilock/JzTSZmYf4kK5UVJLZzCi9w/rYz-WTrTgUyaUDk1YC511A/Widescreen720p/Digilock%20Digilink%20App%20Highlight%20V6.mp4'}
                  poster={posterDigilink} />
            </div>
          </section>
          <section className="see-locks">
            <div className="container">
              <a href="/products/locks/" className="btn btn--orange">
                <Trans>see_locks</Trans>
              </a>
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
