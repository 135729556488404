import React from 'react';
import {Player, ControlBar} from "video-react";

const ReactVideoHomepage = ({ vURL, poster, label }) => {
  const handleVideoEnd = (player) => {
    player.currentTime = 0;
    player.load();
  }
  return(
      <Player
          playsInline
          poster={poster}
          src={vURL}
          muted={true}
          autoPlay={true}
          disableDefaultControls={true}
          onEnded={(event) => handleVideoEnd(event.target)}
      >
        <ControlBar disableCompletely={true} />
      </Player>
  )
}
export default ReactVideoHomepage;