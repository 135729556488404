import React from "react";
import Slider from "react-slick";
import {StaticImage} from "gatsby-plugin-image";

import logoVw from '../../images/home/client-logos/VW_Volkswagen.svg';
import logoTesla from '../../images/home/client-logos/Tesla-logo.svg';
import logoSales from '../../images/home/client-logos/salesforce.svg';
import logoIbm from '../../images/home/client-logos/ibm_logo.svg';
import logoArmani from '../../images/home/client-logos/logo-armanihotels.svg';
import logoNiners from '../../images/home/client-logos/49ers_logo.svg';
import logoAmazon from '../../images/home/client-logos/logo-amazon.svg';
import logoMasterCard from '../../images/home/client-logos/mastercard-2.svg';
import logoMirage from '../../images/home/client-logos/Mirage_Hotel_and_Casino.svg';
import logoUnilever from '../../images/home/client-logos/unilever-2-logo-svg-vector.svg';
import logoSaints from '../../images/home/client-logos/nfl-new-orleans-saints-team-logo.svg';
import logoUFC from '../../images/home/client-logos/ufclogo.svg';
import logoWalgreens from '../../images/home/client-logos/walgreens-logo-vector.svg';
import logoPrinceton from '../../images/home/client-logos/princeton.svg';
import logoIbis from '../../images/home/client-logos/ibis-hotels.svg';
import logoFitbit from '../../images/home/client-logos/fitbit.svg';
import logoGenentech from '../../images/home/client-logos/genentech.svg';
import logoTommy from '../../images/home/client-logos/tommy-hilfiger.svg';
import logoSBO from '../../images/home/client-logos/SBOHlogoagain.png';
import logoAmgen from '../../images/home/client-logos/amgen-logo-vector.svg';
import logoAhnanti from '../../images/home/client-logos/Ananti.svg';
import logoAstellas from '../../images/home/client-logos/Astellas_logo.svg';
import logoFannieMae from '../../images/home/client-logos/fannie-mae-logo_svg.svg';
import logoFreezeLab from '../../images/home/client-logos/freezelab-logo.svg';
import logoIowaState from '../../images/home/client-logos/iowa-state-university-logo-svg-vector.svg';


class ReactSlick extends React.Component {
  render() {
    let settings = {
      dots: false,
      arrows: false,
      infinite: true,
      slidesToShow: 6,
      swipeToSlide: true,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 2000,
      cssEase: "ease",
      responsive: [
        {
          breakpoint: 972,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
        <div className="slider-container">
          <Slider {...settings}>
            <div>
              <img loading={'lazy'} src={logoVw} alt="Volkswagen" width={'66'} height={'50'} style={{marginLeft:'-16px'}}/>
            </div>
            <div>
              <img loading={'lazy'} src={logoTommy} alt="Tommy Hilfiger" width={'170'} height={'180'} style={{marginTop:'-15px'}}/>
            </div>
            <div>
              <StaticImage
                  src={'../../images/home/client-logos/jackesonville-jaguars.png'}
                  loading={'lazy'}
                  width={90}
                  height={71}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Jacksonville Jaguars"
              />
            </div>
            <div>
              <img loading={'lazy'} src={logoAmazon} alt="Amazon" width={'90'} height={'90'} />
            </div>
            <div>
              <img loading={'lazy'} style={{marginTop:'-20px'}} src={logoTesla} alt="Tesla" width={'90'} height={'90'} />
            </div>
            <div>
              <img loading={'lazy'} src={logoSales} alt="salesforce" width={'70'} height={'70'} />
            </div>
            <div>
              <img loading={'lazy'} src={logoIbis} alt="ibis" width={'70'} height={'70'}/>
            </div>
            <div>
              <img loading={'lazy'} src={logoMasterCard} alt="Mastercard" width={'70'} height={'70'}/>
            </div>
            <div>
              <StaticImage
                  src={'../../images/home/client-logos/logo-nike.jpg'}
                  loading={'lazy'}
                  width={100}
                  height={100}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Nike"
              />
            </div>
            <div>
              <StaticImage
                  src={'../../images/home/client-logos/Soulcycle.png'}
                  loading={'lazy'}
                  width={100}
                  height={53}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="SoulCycle"
              />
            </div>
            <div>
              <StaticImage
                  src={'../../images/home/client-logos/louvre.png'}
                  loading={'lazy'}
                  width={155}
                  height={80}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Louvre"
              />
            </div>
            <div>
              <img loading={'lazy'} src={logoMirage} alt="Mirage" width={'70'} height={'70'}/>
            </div>
            <div>
              <img loading={'lazy'} src={logoIbm} alt="IBM" width={'70'} height={'70'} />
            </div>
            <div>
              <img loading={'lazy'} src={logoSaints} alt="Saints" width={'50'} height={'50'}/>
            </div>
            <div>
              <img loading={'lazy'} src={logoUnilever} alt="Unilever" width={'50'} height={'50'}/>
            </div>
            <div>
              <img loading={'lazy'} src={logoUFC} alt="UFC" width={'70'} height={'70'}/>
            </div>
            <div>
              <img loading={'lazy'} src={logoArmani} alt="Armani" width={'80'} height={'80'} />
            </div>
            <div>
              <img loading={'lazy'} src={logoWalgreens} alt="Walgreens" width={'100'} height={'100'}/>
            </div>
            <div>
              <img loading={'lazy'} src={logoNiners} alt="SF Forty Niners" width={'70'} height={'70'} />
            </div>
            <div>
              <StaticImage
                  src={'../../images/home/client-logos/roche.png'}
                  loading={'lazy'}
                  width={80}
                  height={42}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Roche"
              />
            </div>
            <div>
              <img loading={'lazy'} src={logoPrinceton} alt="Princeton University" width={'100'} height={'100'}/>
            </div>
            <div>
              <img loading={'lazy'} src={logoFitbit} alt="Fitbit" width={'90'} height={'90'}/>
            </div>
            <div>
              <img loading={'lazy'} src={logoGenentech} alt="Genentech" width={'100'} height={'100'}/>
            </div>
            <div>
              <img loading={'lazy'} src={logoSBO} alt="SBO" width={'70'} height={'70'}/>
            </div>
            <div>
              <img loading={'lazy'} src={logoAmgen} alt="Amgen" width={'70'} height={'70'}/>
            </div>
            <div>
              <StaticImage
                  src={'../../images/home/client-logos/AF.png'}
                  loading={'lazy'}
                  width={70}
                  height={70}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="AF"
              />
            </div>
            <div>
              <img loading={'lazy'} src={logoAhnanti} alt="Ahnanti" width={'100'} height={'100'}/>
            </div>
            <div>
              <img loading={'lazy'} src={logoAstellas} alt="Astellas" width={'100'} height={'100'}/>
            </div>
            <div>
              <StaticImage
                  src={'../../images/home/client-logos/cityofamsterdam.png'}
                  loading={'lazy'}
                  width={113}
                  height={50}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="City of Amsterdam"
              />
            </div>
            <div>
              <StaticImage
                  src={'../../images/home/client-logos/coloradocollage.png'}
                  loading={'lazy'}
                  width={277}
                  height={65}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Colorado College"
              />
            </div>
            <div>
              <img loading={'lazy'} src={logoFannieMae} alt="Fannie Mae" width={'120'} height={'120'}/>
            </div>
            <div>
              <img loading={'lazy'} src={logoFreezeLab} alt="Freeze Lab" width={'140'} height={'140'}/>
            </div>
            <div>
              <img loading={'lazy'} src={logoIowaState} alt="Iowa State University" width={'140'} height={'140'}/>
            </div>
            <div>
              <StaticImage
                  src={'../../images/home/client-logos/loyola.png'}
                  loading={'lazy'}
                  width={218}
                  height={58}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Loyola"
              />
            </div>
          </Slider>
        </div>
    );
  }
}

export default ReactSlick;